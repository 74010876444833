import React, { useState, useEffect } from 'react';
import { Clock, X } from 'lucide-react';

const LeaderboardApp = () => {
  const [participants, setParticipants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const participantsPerPage = 10;

  useEffect(() => {
    const data = [
      { id: 1, name: "Raphaël Dimanche", team: "Team 5", points: 10787 },
      { id: 2, name: "Qinglian Zhang", team: "Team 5", points: 8818 },
      { id: 3, name: "Léa Sanquer", team: "Team 5", points: 5963 },
      { id: 4, name: "Guilhem Soares", team: "Team 5", points: 6387 },
      { id: 5, name: "Eya Ben Romdhane", team: "Team 5", points: 2638 },
  
      { id: 6, name: "Didier Gribeauval", team: "Vendredi 13", points: 6920 },
      { id: 7, name: "Olivier Daniel", team: "Vendredi 13", points: 9087 },
      { id: 8, name: "Pablo Veliz", team: "Vendredi 13", points: 6419 },
      { id: 9, name: "Juliette LE ROUX", team: "Vendredi 13", points: 7668 },
      { id: 10, name: "Adele Crasnier", team: "Vendredi 13", points: 4864 },
  
      { id: 11, name: "Jonathan Bolzer", team: "les lions fort de zinzin", points: 7939 },
      { id: 12, name: "Emeric Maillard", team: "les lions fort de zinzin", points: 6613 },
      { id: 13, name: "Eloïse Denié", team: "les lions fort de zinzin", points: 5214 },
      { id: 14, name: "Davin Sufer", team: "les lions fort de zinzin", points: 6357 },
  
      { id: 15, name: "Charles Demontigny", team: "Team 8", points: 13055 },
      { id: 16, name: "Brian Naar", team: "Team 8", points: 5220 },
      { id: 17, name: "Florian Mesny", team: "Team 8", points: 4460 },
      { id: 18, name: "Sébastien C", team: "Team 8", points: 2087 },
      { id: 19, name: "Alexandra Delory", team: "Team 8", points: 1808 },
  
      { id: 20, name: "Armel Congar", team: "Team One, One Team", points: 7004 },
      { id: 21, name: "Anne Chabert", team: "Team One, One Team", points: 5698 },
      { id: 22, name: "Anaïs Tholly", team: "Team One, One Team", points: 4001 },
      { id: 23, name: "Joris Vaillant", team: "Team One, One Team", points: 2254 },
      { id: 24, name: "nico C3pH", team: "Team One, One Team", points: 1278 },
  
      { id: 25, name: "Edouard Soares", team: "Les seconds", points: 7178 },
      { id: 26, name: "Jc Mallet", team: "Les seconds", points: 5204 },
      { id: 27, name: "Vincent Fliniaux", team: "Les seconds", points: 3526 },
      { id: 28, name: "Jean-François CHOTARD", team: "Les seconds", points: 1725 },
  
      { id: 29, name: "Anne Gerard", team: "Team 7", points: 5089 },
      { id: 30, name: "Damien Alexandre", team: "Team 7", points: 5979 },
      { id: 31, name: "Eleonore GODOSZ", team: "Team 7", points: 3557 },
      { id: 32, name: "Aymie Abraham", team: "Team 7", points: 3496 },
      { id: 33, name: "Anthony Battah", team: "Team 7", points: 637 },
  
      { id: 34, name: "Nathalie Dubreuil", team: "ENN.R.JJ", points: 7115 },
      { id: 35, name: "Jordan Jacquier", team: "ENN.R.JJ", points: 1759 },
      { id: 36, name: "Alex Ratchev", team: "ENN.R.JJ", points: 3767 },
      { id: 37, name: "Jennifer Chiotti", team: "ENN.R.JJ", points: 1788 },
      { id: 37, name: "Enzo Angeles", team: "ENN.R.JJ", points: 1469 }
  ];
    const sortedData = data.sort((a, b) => b.points - a.points);
    setParticipants(sortedData);

    document.body.style.backgroundColor = '#e2c09f';
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.minHeight = '100vh';
    document.body.style.display = 'flex';
    document.body.style.flexDirection = 'column';
  }, []);

  const indexOfLastParticipant = currentPage * participantsPerPage;
  const indexOfFirstParticipant = indexOfLastParticipant - participantsPerPage;
  const currentParticipants = participants.slice(indexOfFirstParticipant, indexOfLastParticipant);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const closeModal = () => setIsModalOpen(false);

  return (
    <div style={{ 
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
      {isModalOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            maxWidth: '80%',
            maxHeight: '80%',
            overflow: 'auto',
            position: 'relative',
          }}>
            <button onClick={closeModal} style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '20px',
              cursor: 'pointer',
            }}>
              <X />
            </button>
            <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>
              🏆 Félicitations à Charles Demontigny, le gagnant du classement général! 🎉
            </h2>
            <p style={{ marginBottom: '20px', textAlign: 'center' }}>
              Les résultats des compétitions secondaires sont disponibles dans l'app de SquadEasy.
            </p>
            <img src="/champion.png" alt="Champion" style={{
              display: 'block',
              maxWidth: '80%',
              height: 'auto',
              margin: '0 auto',
              borderRadius: '10px'
            }} />
          </div>
        </div>
      )}
      <div style={{ 
        padding: '20px', 
        maxWidth: '800px', 
        margin: '0 auto',
        width: '100%',
        boxSizing: 'border-box',
        flex: 1,
      }}>
        <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px', color: '#333' }}>Classement Général - SquadEasy MCW</h1>
        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', fontSize: '12px', color: '#666' }}>
          <Clock size={14} style={{ marginRight: '5px' }} />
          Dernière mise à jour faite à 6h aujourd'hui
        </div>
        <div style={{ borderRadius: '10px', overflow: 'hidden', backgroundColor: 'white' }}>
          <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: 0 }}>
            <thead>
              <tr>
                <th style={{ padding: '10px', textAlign: 'left', borderBottom: '2px solid #ddd' }}>Rang</th>
                <th style={{ padding: '10px', textAlign: 'left', borderBottom: '2px solid #ddd' }}>Nom</th>
                <th style={{ padding: '10px', textAlign: 'left', borderBottom: '2px solid #ddd' }}>Équipe</th>
                <th style={{ padding: '10px', textAlign: 'right', borderBottom: '2px solid #ddd' }}>Points</th>
              </tr>
            </thead>
            <tbody>
              {currentParticipants.map((participant, index) => (
                <tr key={participant.id} style={{ backgroundColor: index % 2 === 0 ? 'rgba(178, 176, 254, 0.3)' : 'white' }}>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', fontSize: indexOfFirstParticipant + index + 1 <= 3 ? '16px' : '14px', fontWeight: indexOfFirstParticipant + index === 0 ? 'bold' : 'normal' }}>
                    {indexOfFirstParticipant + index + 1}
                    {indexOfFirstParticipant + index + 1 === 1 && ' 🥇'}
                    {indexOfFirstParticipant + index + 1 === 2 && ' 🥈'}
                    {indexOfFirstParticipant + index + 1 === 3 && ' 🥉'}
                  </td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', fontSize: indexOfFirstParticipant + index + 1 <= 3 ? '16px' : '14px', fontWeight: indexOfFirstParticipant + index === 0 ? 'bold' : 'normal' }}>{participant.name}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', fontSize: indexOfFirstParticipant + index + 1 <= 3 ? '16px' : '14px', fontWeight: indexOfFirstParticipant + index === 0 ? 'bold' : 'normal' }}>{participant.team}</td>
                  <td style={{ padding: '10px', textAlign: 'right', borderBottom: '1px solid #ddd', fontSize: indexOfFirstParticipant + index + 1 <= 3 ? '16px' : '14px', fontWeight: indexOfFirstParticipant + index === 0 ? 'bold' : 'normal' }}>{participant.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
          {Array.from({ length: Math.ceil(participants.length / participantsPerPage) }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => paginate(i + 1)}
              style={{
                padding: '10px',
                margin: '0 5px',
                border: 'none',
                backgroundColor: currentPage === i + 1 ? '#333' : '#ddd',
                color: currentPage === i + 1 ? 'white' : '#333',
                cursor: 'pointer',
              }}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
      <footer style={{
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        padding: '10px',
        textAlign: 'center',
        fontSize: '12px',
        color: '#666',
      }}>
Cette application est développée de manière anonyme et n'est à aucun moment reliée, de près ou de loin, à QuébGPT ou Charles Demontigny.      </footer>
    </div>
  );
};

export default LeaderboardApp;